import { gql } from "@apollo/client";
export const CoursesList = gql`
query Query($coursesId: String) {
  courses(id: $coursesId) {
    totalCount
    edges {
      node {
        id
        title
        description
        type
        link
        videoUrl
        videoTitle
        accelerator {
          id
          name
          description
          createdBy {
            id
            email
          }
          img
          status
          createdAt
          avgRating
          highlight
        }
        status
      }
      cursor
    }
  }
}
`
import { gql } from "@apollo/client";
export const SingleCourseDetail = gql`
query Query($getCourseByIdId: String) {
  getCourseById(id: $getCourseByIdId) {
    id
    title
    description
    type
    link
    videoUrl
    videoTitle
    accelerator {
      id
      name
      description
      createdBy {
        id
        email
      }
      img
      status
      createdAt
      avgRating
      highlight
    }
    status
  }
}
`;
import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";

export default function ModalWrapper({ setOpen, open, children, width, height, borderRadius }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: width || "auto", // default to "auto" if width is not provided
            height: height || "auto", // default to "auto" if height is not provided
            borderRadius: borderRadius || "8px", // default to "8px" if borderRadius is not provided
          }
        }}
      >
        <div style={{ textAlign: "end", padding: "20px", cursor: "pointer" }} onClick={handleClose}>
          <CloseIcon />
        </div>
        {children}
      </Dialog>
    </div>
  );
}


import { gql } from '@apollo/client';

export const userCompany = gql`
query Query($search: String) {
    getCompany(search: $search) {
      edges {
        cursor
        node {
          id
          name
          isActive
        }
      }
      totalCount
    }
  }
`;




import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ModalHeading from "./ModalHeading";
import ModalWrapper from "./ModalWrapper";
import DropDown from "../input/DropDown";
import CustomButton from "../Button/CustomButton";
import Input from "../input/Input";
import FileUpload from "../input/FileUpload";
import { useLazyQuery, useMutation } from "@apollo/client";
import { errorToast, successToast } from "src/utils/toaster";
import { CourseAdd } from "src/hook/mutations/addCourse";
import { s3UploadMethod } from "src/utils/awsFileUploder";
import { SingleCourseDetail } from "src/hook/query/getCourseById";
import { CourseUpdate } from "src/hook/mutations/updateCourse";

const ModalContainer = styled.div``;

const FormContainer = styled.div`
  width: 454px;
  margin: 20px auto;
`;

const NewCourse = ({ open, setOpen, refetch, courseIdState, courseIdSingle }) => {
  const userinfo = JSON.parse(localStorage.getItem("UserInfo") || "{}");
  const authHeader = localStorage.getItem("TOKEN");
  const [inputValue, setInputValue] = useState({
    title: "",
    description: "",
    selectType: "",
    videotitle: "",
    videourl: "",
  });
  const [inputError, setInputError] = useState({
    title: "",
    description: "",
    selectType: "",
    videotitle: "",
    videourl: "",
    file: ""
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [singleCourseQuery] = useLazyQuery(SingleCourseDetail, {
    onCompleted: refetch, fetchPolicy: "network-only"
  });


  useEffect(() => {
    const fetchData = async () => {
      if (courseIdSingle) {
        const result = await singleCourseQuery({
          variables: {
            getCourseByIdId: courseIdSingle,
          },
          context: {
            headers: {
              Authorization: `Bearer ${authHeader}`,
            },
          },
        });

        if (result) {
          const singleid = result.data.getCourseById;
          let isVideo=singleid.type==="Video"?singleid.link:""
          let isPDForDoc=singleid.type==="Document"||singleid.type==="PDF"?singleid.link:null
          setInputValue({
            title: singleid.title,
            description: singleid.description,
            selectType: singleid.type,
            videotitle: singleid.videoTitle,
            videourl: isVideo,
          });
          setSelectedFile(isPDForDoc)
        }
      }
    };
    fetchData();
  }, [open, courseIdSingle, authHeader]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (validateFile(file)) {
        setSelectedFile(file);
      } else {
        setInputError({
          title: "",
          description: "",
          selectType: "",
          videotitle: "",
          videourl: "",
          file: "Invalid file type or size. Please upload a PDF or MS office file (max 10MB)."
        })
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      if (validateFile(file)) {
        setSelectedFile(file);
      } else {
        setInputError({
          title: "",
          description: "",
          selectType: "",
          videotitle: "",
          videourl: "",
          file: "Invalid file type or size. Please upload a PDF or MS office file (max 10MB)."
        })
      }
    }
  };

  const validateFile = (file) => {
    const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
    const maxSize = 10 * 1024 * 1024; // 10MB

    return allowedTypes.includes(file.type) && file.size <= maxSize;
  };

  const handleChange = (fieldName) => (event) => {
    const { value } = event.target;

    setInputError((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
    setInputValue((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const isDocumentType = inputValue.selectType === "Document" || inputValue.selectType === "PDF";
  const validateForm = () => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    let isValid = true;
    const errors = {
      title: "",
      description: "",
      selectType: "",
      videotitle: "",
      videourl: "",
      file: "",
    };

    const setError = (field, message) => {
      errors[field] = message;
      isValid = false;
    };

    if (!inputValue.title?.trim()) {
      setError("title", "Title is required");
    }

    if (!inputValue.description?.trim()) {
      setError("description", "Description is required");
    }

    if (!inputValue.selectType?.trim()) {
      setError("selectType", "Type is required");
    }

    if (isDocumentType && !selectedFile) {
      setError("file", "File upload is required");
    }

    if (!isDocumentType && !inputValue.videotitle?.trim()) {
      setError("videotitle", "Video title is required");
    }

    if (!isDocumentType && !urlPattern.test(inputValue.videourl?.trim())) {
      setError("videourl", "Please enter a valid URL in the Video URL field");
    }

    setInputError(errors);
    return isValid;
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setLoading(true);
      try {
        if (courseIdSingle) {
          await updateCourseById(courseIdSingle);
        } else {
          await addCoursehandler(courseIdState);
        }
      } catch (error) {
        errorToast(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const [addCourse] = useMutation(CourseAdd, {
    onCompleted: refetch, fetchPolicy: "network-only"
  });

  const addCoursehandler = async (id) => {
    try {
      let location = null;

      if (selectedFile) {
        const folderName = `profilePicture/${userinfo.email}`;
        location = await s3UploadMethod([selectedFile], folderName);
      }

      await addCourse({
        variables: {
          input: {
            accelerator: id,
            description: inputValue.description,
            link: selectedFile ? location[0] : inputValue.videourl,
            title: inputValue.title,
            type: inputValue.selectType,
            videoTitle: inputValue.videotitle ?? null,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${authHeader}`,
          },
        },
      });

      setOpen(false);
      setInputValue({
        title: "",
        description: "",
        selectType: "",
        videotitle: "",
        videourl: "",
      });
      setSelectedFile(null);
      successToast("Course successfully added to accelerator.");
    } catch (error) {
      errorToast(error);
    }
  };
  const [updateCourseQuery] = useMutation(CourseUpdate, {
    onCompleted: refetch,
  });

  const updateCourseById = async (id) => {
    try {
      let location = null;
      const isFile = typeof selectedFile !== "string" && selectedFile;
      const fileOrVideo = inputValue.selectType === "Video" ? inputValue.videourl : selectedFile;
  
      if (isFile) {
        const folderName = `profilePicture/${userinfo.email}`;
        location = await s3UploadMethod([selectedFile], folderName);
      }
  
      const updateVariables = {
        input: {
          id: id,
          description: inputValue.description,
          link: isFile ? location?.[0] : fileOrVideo,
          title: inputValue.title,
          type: inputValue.selectType,
          videoTitle: inputValue.videotitle ?? null,
        },
      };
  
      await updateCourseQuery({
        variables: updateVariables,
        context: {
          headers: { Authorization: `Bearer ${authHeader}` },
        },
      });
  
      setOpen(false);
      setInputValue({
        title: "",
        description: "",
        selectType: "",
        videotitle: "",
        videourl: "",
      });
      setSelectedFile(null);
      successToast("Course updated successfully ");
    } catch (error) {
      errorToast(error);
    }
  };
  




  const options = [
    { value: "Document", label: "Document" },
    { value: "PDF", label: "PDF" },
    { value: "Video", label: "Video" },
  ];
  return (
    <>
      <ModalWrapper
        setOpen={setOpen}
        open={open}
        width="686px"
        height="fit-content"
        borderRadius="30px"
      >
        <ModalContainer>
          <ModalHeading heading="Add New Course" />
          <FormContainer>
            <Input
              value={inputValue.title}
              placeholder="Enter Title"
              onChange={handleChange("title")}
              disabled={false}
              error={inputError.title}
              label="Title"
            />
            <Input
              value={inputValue.description}
              placeholder="Enter Description"
              onChange={handleChange("description")}
              disabled={false}
              error={inputError.description}
              label="Description"
            />
            <DropDown
              options={options}
              label="Type"
              value={inputValue.selectType}
              error={inputError.selectType}
              onChange={handleChange("selectType")}
            />
            {inputValue.selectType === "Video" &&
              <>
                <Input
                  value={inputValue.videotitle}
                  placeholder="Enter video title"
                  onChange={handleChange("videotitle")}
                  disabled={false}
                  error={inputError.videotitle}
                  label="Video Title"
                />
                <Input
                  value={inputValue.videourl}
                  placeholder="Paste video URL"
                  onChange={handleChange("videourl")}
                  disabled={false}
                  error={inputError.videourl}
                  label="Video URL"
                />
              </>
            }

            {isDocumentType && <FileUpload
              selectedFile={selectedFile}
              handleFileChange={handleFileChange}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              error={inputError.file}
            />}
            <CustomButton onClick={handleButtonClick} buttonText="Submit" loading={loading} />
          </FormContainer>
        </ModalContainer>
      </ModalWrapper>
    </>
  );
};

export default NewCourse;

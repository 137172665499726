import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { AliceBlue, PrimaryColor } from "../theme/Theme";
import { AcceleratorIcon, DashBoardIcon } from "./exports/SVG";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";

const drawerWidth = 237;

const Sidebar = () => {
  const location = useLocation();

  const commonListItemStyles = {
    border: "2px solid transparent",
    "&:hover": {
      background: AliceBlue,
      border: `2px solid ${PrimaryColor}`,
    },
  };
  const blueIconStyles = {
    color: PrimaryColor,
  };
  const menuItems = [
    { text: "Dashboard", icon: DashBoardIcon },
    { text: "Accelerator", icon: AcceleratorIcon },
    { text: "Company", icon: <BusinessRoundedIcon style={blueIconStyles} /> },
    { text: "Users", icon: <PeopleRoundedIcon style={blueIconStyles} /> },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {menuItems.map(({ text, icon }, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                component={Link}
                to={`/auth/${text.toLowerCase()}`}
                sx={{
                  ...commonListItemStyles,
                  ...(location.pathname === `/auth/${text.toLowerCase()}` && {
                    background: AliceBlue,
                    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                      color: PrimaryColor,
                    },
                  }),
                }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  primary={text}
                  sx={{
                    "& .MuiTypography-root": {
                      color: PrimaryColor,
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontWeight: "600",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;

import styled from "@emotion/styled";
import { DuneColor, ErrorColor, Porcelaincolor, PrimaryColor } from "src/theme/Theme";

const Label = styled.label`
  color: ${DuneColor};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
`;

const InputBox = styled.input`
  padding-left: 10px;
  width: 454px;
  height: 44px;
  border-radius: 5px;
  border: 1px solid ${Porcelaincolor};
  color:${DuneColor};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  &:focus {
    border: 2px solid ${PrimaryColor};
    background-color: ${Porcelaincolor};
  }
`;
const InputContainer = styled.div`
  margin-bottom: 16px;
`;

const ErrorMessage = styled.div`
  color: ${ErrorColor};
  font-style: italic;
  font-size: 12px;
  font-weight: 600;
`;
const Input = ({ label, value, placeholder, onChange, disabled, error }) => (
  <>
    <Label>{label}</Label>
    <InputContainer>
      <InputBox
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputContainer>
  </>
);

export default Input;


import { gql } from '@apollo/client';

export const userQuery = gql`
query Query($search: String) {
  getUser(search: $search) {
    totalCount
    edges {
      node {
        id
        email
        mobileNumber
        countryCode
        jobRole
        experience
        firstWishlist
        company {
          id
          name
          isActive
        }
        weLeadUserInCompany
        fullName
        companySize
        profilePicture
        industry
        login_isActive
      }
      cursor
    }
  }
}
`;




import React, { useEffect } from 'react';
import { useParams } from 'react-router'
import CoursesListUi from 'src/components/ui/CoursesListUi';

const AcceleratorCourses = () => {
  const { courseid } = useParams()
  const [courseIdState, setCourseIdState] = React.useState("")

  useEffect(() => {
    if (courseid) {
      setCourseIdState(courseid)
    }
  }, [courseid])

  return (
    <>
      <CoursesListUi courseIdState={courseIdState} />
    </>
  )
}

export default AcceleratorCourses
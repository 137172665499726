import React, { useState } from "react";
import styled from "@emotion/styled";
import { TextField, InputAdornment, MenuItem, Select } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  AlabasterColor,
  DuneColor,
  PrimaryColor,
  TunaColor,
} from "../theme/Theme";

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 20px 0;
  border-bottom: 1px solid ${PrimaryColor};
  padding-bottom: 10px;
`;

const LeftText = styled.div`
  color: ${TunaColor};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
`;

const RightFilterOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 4;
`;

const commonStyles = {
  width: "216px",
  height: "44px",
  borderRadius: "6px",
  color: DuneColor,
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 400,
  background: AlabasterColor,
  outline: "none",
  border: "none",
};

interface SearchFilterProps {
  leftText: string;
  selectData?: { value: string | number; label: string }[];
  onSearchChange?: (value: string) => void;
  onSelectChange?: (value: string) => void;
}

const SearchFilter: React.FC<SearchFilterProps> = ({
  leftText,
  selectData = [],
  onSearchChange = () => {
     // Empty function intentionally provided as a default.
    // Implementation may be added by the consumer if needed.
  },
  onSelectChange = () => {
     // Empty function intentionally provided as a default.
    // Implementation may be added by the consumer if needed.
  },
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    onSearchChange(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    onSelectChange(event.target.value);
  };

  return (
    <FilterContainer>
      <LeftText>{leftText}</LeftText>
      <RightFilterOptions>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search"
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              ...commonStyles,
              "& .MuiInputBase-root": { height: "44px" },
              marginRight: "8px",
            }}
            value={searchText}
            onChange={handleSearchChange}
          />

        {selectData.length > 0 && (
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={commonStyles}
          >
            {selectData.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </RightFilterOptions>
    </FilterContainer>
  );
};

export default SearchFilter;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ModalWrapper from "./ModalWrapper";
import ModalHeading from "./ModalHeading";
import Input from "../input/Input";
import AvatarFileUpload from "src/avatar/AvatarFileUpload";
import CustomButton from "../Button/CustomButton";
import { CameraSVG } from "../exports/SVG";
import { AcceleratorAdd } from "src/hook/mutations/addAccelerator";
import { useLazyQuery, useMutation } from "@apollo/client";
import { errorToast, successToast } from "src/utils/toaster";
import { s3UploadMethod } from "../../utils/awsFileUploder";
import { SingleAcceleratorDetail } from "src/hook/query/getAcceleratorById";
import { AcceleratorUpdate } from "src/hook/mutations/updateAccelerator";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const ModalContainer = styled.div``;
const FormContainer = styled.div`
  width: 454px;
  margin: 20px auto;
`;
interface NewAcceleratorProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
  accId?: string;
  setAccId?: React.Dispatch<React.SetStateAction<string | undefined>>;
}
const NewAccelerator: React.FC<NewAcceleratorProps> = ({
  open,
  setOpen,
  refetch,
  accId,
  setAccId,
}) => {
  const authHeader = localStorage.getItem("TOKEN");
  const userinfo = JSON.parse(localStorage.getItem("UserInfo") || "{}");
  const [inputValue, setInputValue] = useState<{ name: string; description: string; highlight: boolean }>({
    name: "",
    description: "",
    highlight: false,
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [inputErrors, setInputErrors] = useState({
    name: "",
    description: "",
    selectedFile: "",
  });
  const [loading, setLoading] = useState(false);
  const [singleAcceleratorQuery] = useLazyQuery(SingleAcceleratorDetail, {
    onCompleted: refetch, fetchPolicy: "network-only"
  });
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        if (accId && isMounted) {
          const result = await singleAcceleratorQuery({
            variables: {
              getAcceleratorByIdId: accId,
            },
            context: {
              headers: {
                Authorization: `Bearer ${authHeader}`,
              },
            },
          });
          if (isMounted && result) {
            const singleid = result.data.getAcceleratorById;
            setInputValue({
              name: singleid.name,
              description: singleid.description,
              highlight: singleid.highlight
            });
            setSelectedFile(singleid.img);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [accId, authHeader, open]);

  
  const handleChange = (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, type, checked } = event.target;

    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));

    setInputValue((prevState) => ({
      ...prevState,
      [fieldName]: type === 'checkbox' ? checked : value,
      highlight: fieldName === 'highlight' ? !prevState.highlight : prevState.highlight,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const errors = { name: "", description: "", selectedFile: "" };
    if (!inputValue.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }
    // else if (!/^[a-zA-Z\s]+$/.test(inputValue.name.trim())) {
    //   errors.name = "Only letters and spaces are allowed in the Name field";
    //   isValid = false;
    // }
    if (!inputValue.description.trim()) {
      errors.description = "Description is required";
      isValid = false;
    }
    if (!selectedFile) {
      errors.selectedFile = "Upload image is required";
      isValid = false;
    }
    setInputErrors(errors);
    return isValid;
  };
  const handleButtonClick = async (event: React.FormEvent) => {
    event.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setLoading(true);
      try {
        if (accId) {
          await updateAcceleratorById(accId);
        } else {
          await addAcceleratorHandler();
        }
      } catch (error) {
        errorToast(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const [addAcceleratorMutation] = useMutation(AcceleratorAdd, {
    onCompleted: refetch,
  });
  const addAcceleratorHandler = async () => {
    try {
      const folderName = `profilePicture/${userinfo.email}`;
      const location = await s3UploadMethod([selectedFile as File], folderName);
      await addAcceleratorMutation({
        variables: {
          input: {
            description: inputValue.description,
            img: location[0],
            name: inputValue.name,
            highlight: inputValue.highlight,
            createdBy: userinfo.id,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${authHeader}`,
          },
        },
      });
      setInputValue({ name: "", description: "", highlight: false });
      setSelectedFile(null);
      successToast("Accelerator Successfully Created!");
      setOpen(false);
    } catch (error) {
      errorToast(error);
    }
  };
  const [updateAcceleratorQuery] = useMutation(AcceleratorUpdate, {
    onCompleted: refetch,
  });

  const isFile = typeof selectedFile !== "string"
  const updateAcceleratorById = async (id: string) => {
    let location = []
    try {
      if (isFile) {
        const folderName = `profilePicture/${userinfo.email}`;
        location = await s3UploadMethod([selectedFile as File], folderName);
      }
      const finalvalue = {
        ...inputValue, img: location[0], id: id
      }
      const { img, ...rest } = finalvalue

      await updateAcceleratorQuery({
        variables: {
          input: isFile ? finalvalue : rest,
        },

        context: {
          headers: {
            Authorization: `Bearer ${authHeader}`,
          },
        },
      });

      successToast("Accelerator updated successfully");
      setAccId && setAccId("");
      setInputValue({ name: "", description: "", highlight: false });
      setSelectedFile(null);
      setOpen(false);
    } catch (error) {
      errorToast(error);
    }
  };


  return (
    <>
      <ModalWrapper
        setOpen={setOpen}
        open={open}
        width="686px"
        height="fit-content"
        borderRadius="30px"
      >
        <ModalContainer>
          <ModalHeading heading="Add New Accelerator" />
          <FormContainer>
            <AvatarFileUpload

              altText="Scdc"
              avatarSize="107px"
              badgeContent={CameraSVG}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              error={inputErrors.selectedFile}
              setInputErrors={setInputErrors}
            />
            <Input
              value={inputValue.name}
              placeholder="Enter name"
              onChange={handleChange("name")}
              disabled={false}
              error={inputErrors.name}
              label="Name"
            />
            <Input
              value={inputValue.description}
              placeholder="Enter description"
              onChange={handleChange("description")}
              disabled={false}
              error={inputErrors.description}
              label="Description"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={inputValue.highlight}
                  onChange={handleChange('highlight')}
                />
              }
              label="Highlight"
            />
           <CustomButton onClick={handleButtonClick} buttonText="Submit" loading={loading} />
          </FormContainer>
        </ModalContainer>
      </ModalWrapper>
    </>
  );
};
export default NewAccelerator;
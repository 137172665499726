import styled from "@emotion/styled";
import { PDFSVG } from "../exports/SVG";
import { DuneColor, ErrorColor, Porcelaincolor, PrimaryColor } from "src/theme/Theme";

const Label = styled.label`
  color: ${DuneColor};
  margin-bottom: 8px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  display: block;
`;

const FileUploadConatiner = styled.label`
  border-radius: 20px;
  border: 1px dashed ${PrimaryColor};
  background: ${Porcelaincolor};
  width: 454px;
  height: 211px;
  cursor: pointer;
  text-align: center;
  place-items: center;
  display: grid;
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const SelectPDFText = styled(Text)`
  color: ${PrimaryColor};
`;

const OrText = styled(Text)`
  color: ${DuneColor};
`;

const DragDrop = styled(Text)`
  color: ${PrimaryColor};
`;
const ErrorMessage = styled.div`
  color: ${ErrorColor};
  font-style: italic;
  font-size: 12px;
  font-weight: 600;
`;
const FileUpload = ({ selectedFile, handleFileChange, handleDragOver, handleDrop, error }) => {

  const extractFileName = (file) => {
    if (!file) {
      return null;
    }

    let decodedFileName;

    if (typeof file === 'string') {
      const urlParts = file.split('/');
      decodedFileName = urlParts[urlParts.length - 1];
    } else {
      decodedFileName = file.name;
    }

    return decodeURIComponent(decodedFileName);
  };
  const fileName = extractFileName(selectedFile);
  return (
    <>
      <Label>Upload document</Label>

      <FileUploadConatiner
        htmlFor="fileInput"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {PDFSVG}
        <SelectPDFText>Select a PDF or MS office file to upload (max 10MB)</SelectPDFText>
        <OrText>OR</OrText>
        <DragDrop>Drag & Drop here</DragDrop>
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          accept=".pdf, .doc, .docx"
          onChange={handleFileChange}
        />
        {selectedFile && (
          <Text>File uploaded: {fileName}</Text>
        )}
      </FileUploadConatiner>

      {error && <ErrorMessage>{error}</ErrorMessage>}

    </>
  );
};

export default FileUpload;

import { Route, Routes,useNavigate } from "react-router";
import { useEffect } from "react";
import ProtectedRoute from "./router/Protected";
import PublicRoute from "./router/publicRoute";
import Status404 from "./content/pages/Status/Status404";
import Status500 from "./content/pages/Status/Status500";
import Login from "./Screens/Login";
import Dashboard from "./Screens/Dashboard";
import Accelerator from "./Screens/Accelerator";
import Company from "./Screens/Company";
import Users from "./Screens/Users";
import { useQuery } from "@apollo/client";
import { TokenVerify } from "./hook/query/verifyToken";
import AcceleratorCourses from "./Screens/AcceleratorCourses";

const MainRouter = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("TOKEN");

  const { loading, data } = useQuery(TokenVerify, {
    variables: { token },
    context: { headers: { Authorization: `Bearer ${token}` } },
  });

  useEffect(() => {
    const handleLogoutAndRedirect = () => {
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("UserInfo");
      navigate("/login");
    };

    const verifyToken = data?.verifyToken;
    if (!token) {
      handleLogoutAndRedirect();
    } else if (!loading) {
      if (!verifyToken ) {
        handleLogoutAndRedirect();
      }
    }
  }, [data, loading, token]);
  return (
    <>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route index element={<Dashboard />} />
          <Route path="/auth/dashboard" element={<Dashboard />} />
          <Route path="/auth/accelerator" element={<Accelerator />} />
          <Route path="/auth/accelerator/:courseid" element={<AcceleratorCourses />} />

          <Route path="/auth/users" element={<Users />} />
          <Route path="/auth/company" element={<Company />} />
        </Route>

        <Route path="404" element={<Status404 />} />
        <Route path="500" element={<Status500 />} />
        <Route path="*" element={<Status404 />} />
      </Routes>
    </>
  );
};

export default MainRouter;

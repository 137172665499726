import { gql } from "@apollo/client";
export const SingleAcceleratorDetail = gql`
query Query($getAcceleratorByIdId: String) {
  getAcceleratorById(id: $getAcceleratorByIdId) {
    id
    name
    description
    createdBy {
      id
      email
    }
    img
    status
    createdAt
    avgRating
    highlight
  }
}
`;
export const PrimaryColor = "#0277B6";
export const SuccessColor = "#2ED47A";
export const ErrorColor = "#E25757";
export const WhiteColor = "#ffffff";
export const DuneColor = "#333";
export const CloudColor = "#C4C4C4";
export const AliceBlue = "#F2F8FB";
export const HarpColor = "#EBEFF2";
export const TunaColor = "#343A40";
export const AlabasterColor="#FAFAFA";
export const Porcelaincolor="#eff2f5"
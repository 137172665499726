import { useState } from "react";
import { PrimaryColor, WhiteColor } from "src/theme/Theme";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import Header from "src/components/Header";
import SuspenseLoader from "src/components/SuspenseLoader";
import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import SearchFilter from "src/components/SearchFilter";
import { userCompany } from "src/hook/query/getCompany";
import ExportButton from "src/components/Button/ExportButton";
import { Box, TablePagination } from "@mui/material";
import CompanyTable from "src/components/Table/CompanyTable";
const ListFilterWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  background: ${WhiteColor};
  padding: 20px;
  margin: 20px 0;
`;
const Company = () => {
  const authHeader = localStorage.getItem("TOKEN");
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { loading, error, data, refetch } = useQuery(userCompany, {
    variables: {
      search: "",
    },
    context: {
      headers: {
        Authorization: `Bearer ${authHeader}`,
      },
    },
  });

  if (loading) return <SuspenseLoader />;
  if (error) return <p>Error: {error.message}</p>;

  let companies = data?.getCompany.edges;

  if (searchInput) {
    companies = companies?.filter((company) => {
      const companyName = company.node.name?.toLowerCase();
      return companyName?.includes(searchInput?.toLowerCase());
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const paginatedCompanies =
    companies &&
    companies?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const exportAllData = companies?.map((company) => ({
    Company: company.node.name,
    Status: company.node.isActive ? "Active" : "Inactive",
  }));

  const tableColumns = [
    { id: 1, name: "COMPANY" },
    { id: 2, name: "STATUS" },
    { id: 3, name: "Block Company" },
  ];

  const blueIconStyles = {
    color: PrimaryColor,
  };

  const handleSearchChange = (searchValue) => {
    setSearchInput(searchValue);
  };
  return (
    <>
      <Header
        heading="Company List"
        headingIcon={<BusinessRoundedIcon style={blueIconStyles} />}
      />
      <ListFilterWrapper>
        <SearchFilter
          leftText={`Total Companies (${data.getCompany.totalCount})`}
          onSearchChange={handleSearchChange}
        />
        <CompanyTable
          TableData={paginatedCompanies}
          columns={tableColumns}
          refetch={refetch}
          authHeader={authHeader}
        />
        <Box
          p={2}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <ExportButton data={exportAllData} fileName="AllCompanyData.xlsx" />
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25]}
            count={companies?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </ListFilterWrapper>
    </>
  );
};

export default Company;

import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { errorToast, successToast } from "src/utils/toaster";
import Logo from "../Asset/WaLeadLogo.png";

const defaultTheme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    username: "",
    password: "",
  });
  const [formErrors, setFormErrors] = React.useState({
    userNameError: "",
    passwordError: "",
  });

  const handleInputChange = (event) => {
    setFormErrors({
      userNameError: "",
      passwordError: "",
    });
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { userNameError: "", passwordError: "" };

    if (!formData.username) {
      newErrors.userNameError = "User name is required";
      isValid = false;
    }

    if (!formData.password) {
      newErrors.passwordError = "Password is required";
      isValid = false;
    }

    setFormErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      LoginApi();
    }
  };

  const LoginApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      username: formData.username?.trim(),
      password: formData.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(`${process.env.REACT_APP_REST_LOGIN_URL_API}/loginadmin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data && result.success === true) {
          successToast(result.message);
          localStorage.setItem("TOKEN", result.data.ownJwtToken.token);
          localStorage.setItem(
            "UserInfo",
            JSON.stringify(result.data.userInfo)
          );
          setFormData({
            username: "",
            password: "",
          });
          navigate("/auth/dashboard");
        } else {
          errorToast(result.message);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={Logo} alt="logo" style={{width:"80%",height:"auto"}}/>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="User Name"
              name="username"
              autoComplete="username"
              autoFocus
              value={formData.username}
              onChange={handleInputChange}
              error={Boolean(formErrors.userNameError)}
              helperText={formErrors.userNameError}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={formData.password}
              onChange={handleInputChange}
              error={Boolean(formErrors.passwordError)}
              helperText={formErrors.passwordError}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? "Submitting..." : "Login"}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

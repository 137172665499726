import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button as MUIButton, Switch } from "@mui/material";
import styled from "@emotion/styled";
import {
  AliceBlue,
  DuneColor,
  ErrorColor,
  PrimaryColor,
  SuccessColor,
  WhiteColor,
} from "../../theme/Theme";
import { errorToast, successToast } from "src/utils/toaster";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AcceleratorUpdate } from "src/hook/mutations/updateAccelerator";
import { AcceleratorDelete } from "src/hook/query/deleteAccelerator";
import AvatarWithName from "src/avatar/AvatarWithName";
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: WhiteColor,
    color: PrimaryColor,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: AliceBlue,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Cellstyles = {
  cell: {
    color: PrimaryColor,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
  },
};

const Contentstyles = {
  cell: {
    color: DuneColor,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
  },
};
const TextStyle = {
  color: DuneColor,
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: 600,
};
const AcceleratorListTable = ({ TableData, columns, refetch, authHeader, GetAccIdCallback }) => {


  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [CrudAcceleratorMutation] = useMutation(AcceleratorUpdate, {
    onCompleted: refetch,
  });


  const blockAcceleratorHandler = (companyId, companyStatus) => {
    CrudAcceleratorMutation({
      variables: {
        input: {
          status: !companyStatus,
          id: companyId,
        },
      },
      context: {
        headers: {
          Authorization: `Bearer ${authHeader}`,
        },
      },
    })
      .then((result) => {
        if (result) {
          successToast("Accelerator blocked successfully");
        }
      })
      .catch((error) => {
        errorToast(error);
      });
  };
  const [deleteAcceleratorMutation] = useLazyQuery(AcceleratorDelete, {
    onCompleted: refetch,
  });
  const deleteAcceleratorHandler = (id) => {
    deleteAcceleratorMutation({
      variables: {
        "deleteAcceleratorByIdId": id
      },
      context: {
        headers: {
          Authorization: `Bearer ${authHeader}`,
        },
      },
    })
      .then((result) => {
        if (result) {
          successToast(result.data.deleteAcceleratorById);
        }
      })
      .catch((error) => {
        errorToast(error);
      });
  };
  const formatDateString = (originalDate) => {
    try {
      return new Date(originalDate)?.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }) || "Invalid Date";
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };


  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((item, index) => {
              return (
                <StyledTableCell
                  align="center"
                  style={Cellstyles.cell}
                  key={item.id}
                >
                  {item.name}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {TableData?.map((company) => (
            <StyledTableRow key={company.node.id}>
              <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                {company.node.name}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                {company.node.description}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                <AvatarWithName
                  width="30"
                  height="30"
                  imageUrl={company.node.img}
                  text={""}
                  textSx={TextStyle}
                />
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                {formatDateString(company.node.createdAt?.slice(0, 10))}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                <MUIButton
                  variant="contained"
                  style={{
                    borderRadius: "5px",
                    backgroundColor: company.node.status
                      ? SuccessColor
                      : ErrorColor,
                    padding: "4px 30px",
                    color: WhiteColor,
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  {company.node.status ? "Active" : "Inactive"}
                </MUIButton>{" "}
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                {/* Block accelerator */}
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() =>
                    blockAcceleratorHandler(company.node.id, company.node.status)
                  }
                >
                  <Switch {...label} defaultChecked={company.node.status} />
                </IconButton>
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                {/* Edit accelerator */}
                <IconButton color="primary" size="small"
                  onClick={() => GetAccIdCallback(company.node.id)}
                >                  <EditIcon />
                </IconButton>
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                {/* Delete accelerator */}
                <IconButton color="error" size="small"
                  onClick={() =>
                    deleteAcceleratorHandler(company.node.id)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                {/* add course   */}
                <Link to={`/auth/accelerator/${company.node.id}`}>
                  <IconButton color="info" size="small">
                    <AddIcon />
                  </IconButton>
                </Link>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AcceleratorListTable;

import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

const CustomButton = ({ onClick, buttonText, loading }) => {
  const buttonStyles = {
    padding: '10px 20px',
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 'normal',
    margin: 'auto',
    display: 'block',
    marginTop: "30px"
  };

  return (
    <Button variant="contained" sx={buttonStyles} onClick={onClick} disabled={loading}
    >
      {loading ? 'Submitting...' : buttonText}
    </Button>
  );
};

CustomButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default CustomButton;
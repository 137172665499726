
import { gql } from '@apollo/client';

export const TokenVerify = gql`
query Query($token: String!) {
    verifyToken(token: $token)
  }
`;




import styled from "@emotion/styled";
import { PrimaryColor } from "src/theme/Theme";

const Heading = styled.div`
  color: ${PrimaryColor};
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
const ModalHeading = ({ heading }) => {
  return <Heading>{heading}</Heading>;
};

export default ModalHeading;


import { gql } from '@apollo/client';

export const AcceleratorUpdate = gql`
mutation Mutation($input: UpdateAcceleratorInput) {
  updateAccelerator(input: $input) {
    id
    name
    description
    createdBy {
      id
      email
    }
    img
    status
    createdAt
    avgRating
    highlight
  }
}
`;


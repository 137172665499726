import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "@emotion/styled";
import { AliceBlue, DuneColor, ErrorColor, PrimaryColor, SuccessColor, WhiteColor } from "../../theme/Theme";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CourseDelete } from "src/hook/query/deleteCourse";
import { errorToast, successToast } from "src/utils/toaster";
import IconButton from "@mui/material/IconButton";
import { Button as MUIButton, Switch } from "@mui/material";
import { CourseUpdate } from "src/hook/mutations/updateCourse";
import EditIcon from "@mui/icons-material/Edit";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: WhiteColor,
    color: PrimaryColor,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: AliceBlue,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,

  },
}));




const Cellstyles = {
  cell: {
    color: PrimaryColor,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
  },
};

const Contentstyles = {
  cell: {
    color: DuneColor,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
  },
};

const ColumnHeads = ["TITLE", "DESCRIPTION", "DATE", "LINK", "STATUS", "BLOCK","EDIT", "DELETE"];
const AcceleratorTable = ({ courseData, refetch, authHeader,GetCourseIdCallback }) => {



  const label = { inputProps: { "aria-label": "Switch demo" } };


  const [CrudCourseMutation] = useMutation(CourseUpdate, {
    onCompleted: refetch,
  });

  const blockCourseHandler = (courseId, courseStatus) => {

    CrudCourseMutation({
      variables: {
        input: {
          status: !courseStatus,
          id: courseId,
        },
      },
      context: {
        headers: {
          Authorization: `Bearer ${authHeader}`,
        },
      },
    })
      .then((result) => {
        if (result) {
          successToast("Course blocked successfully");
        }
      })
      .catch((error) => {
        errorToast(error);
      });
  };

  const [deleteCourseMutation] = useLazyQuery(CourseDelete, {
    onCompleted: refetch,
  });
  const deleteCourseHandler = (id) => {
    deleteCourseMutation({
      variables: {
        "deleteCourseByIdId": id
      },
      context: {
        headers: {
          Authorization: `Bearer ${authHeader}`,
        },
      },
    })
      .then((result) => {
        if (result) {
          successToast(result.data.deleteCourseById);
        }
      })
      .catch((error: any) => {
        errorToast(error);
      });
  };
  const formatDateString = (originalDate) => {
    try {
      return new Date(originalDate)?.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }) || "Invalid Date";
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };


  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {ColumnHeads.map((item, index) => {
              return (
                <StyledTableCell
                  align="center"
                  style={Cellstyles.cell}
                  key={index}
                >
                  {item}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>

          {courseData?.map((row) => {
            return (
              <StyledTableRow key={row.cursor}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  style={Contentstyles.cell}
                  align="center"
                >
                  {row.node.title}
                </StyledTableCell>
                <StyledTableCell align="center" style={Contentstyles.cell}>
                  {row.node.description}
                </StyledTableCell>
                <StyledTableCell align="center" style={Contentstyles.cell}>
                  {formatDateString(row.node.accelerator.createdAt?.slice(0, 10))}
                </StyledTableCell>
                <StyledTableCell align="center" style={Contentstyles.cell} >
                  {row.node.link && <a href={row.node.link} target="_blank" style={{ textDecoration: 'none', color: PrimaryColor }}>
                    {row.node.type}
                  </a>}

                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  style={Contentstyles.cell}
                  align="center"
                >
                  <MUIButton
                    variant="contained"
                    style={{
                      borderRadius: "5px",
                      backgroundColor: row.node.status
                        ? SuccessColor
                        : ErrorColor,
                      padding: "4px 30px",
                      color: WhiteColor,
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    {row.node.status ? "Active" : "Inactive"}
                  </MUIButton>
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  style={Contentstyles.cell}
                  align="center"
                >
                  {/* Block course */}
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() =>
                      blockCourseHandler(row.node.id, row.node.status)
                    }
                  >
                    <Switch {...label} defaultChecked={row.node.status} />
                  </IconButton>
                </StyledTableCell>

                <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                {/* Edit course */}
                <IconButton color="primary" size="small"
                  onClick={() => GetCourseIdCallback(row.node.id)}
                >                  
                <EditIcon />
                </IconButton>
              </StyledTableCell>

                <StyledTableCell
                  component="th"
                  scope="row"
                  style={Contentstyles.cell}
                  align="center"
                >
                  {/* Delete course */}
                  <IconButton color="error" size="small"
                    onClick={() =>
                      deleteCourseHandler(row.node.id)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            )
          })}


        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AcceleratorTable;
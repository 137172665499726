import AcceleratorUi from "src/components/ui/AcceleratorUi";

const Accelerator = () => {

  return (
    <>
         <AcceleratorUi />
    </>
  );
};

export default Accelerator;

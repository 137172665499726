import { useState } from "react";
import { PrimaryColor, WhiteColor } from "src/theme/Theme";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import Header from "src/components/Header";
import SuspenseLoader from "src/components/SuspenseLoader";
import { useQuery } from "@apollo/client";
import { userQuery } from "src/hook/query/getUser";
import styled from "@emotion/styled";
import SearchFilter from "src/components/SearchFilter";
import ExportButton from "src/components/Button/ExportButton";
import { Box, TablePagination } from "@mui/material";
import UserTable from "src/components/Table/UserTable";
const ListFilterWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  background: ${WhiteColor};
  padding: 20px;
  margin: 20px 0;
`;
const Users = () => {
  const authHeader = localStorage.getItem("TOKEN");
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { loading, error, data, refetch } = useQuery(userQuery, {
    variables: {
      search: "",
    },
    context: {
      headers: {
        Authorization: `Bearer ${authHeader}`,
      },
    },
  });
  if (loading) return <SuspenseLoader />;
  if (error) return <p>Error: {error.message}</p>;
  let users = data?.getUser.edges;
  if (searchInput) {
    users = users?.filter((user) => {
      const fullName = user.node.fullName?.toLowerCase();
      const email = user.node.email?.toLowerCase();
      const searchLower = searchInput?.toLowerCase();
      return fullName?.includes(searchLower) || email?.includes(searchLower);
    });
  }

  const handlechangepage = (event, newpage) => {
    setPage(newpage);
  };
  const handleRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const paginatedCompanies =
    users && users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const exportAllData = users?.map((user) => ({
    Company: user.node.fullName,
    Email: user.node.email,
    Status: user.node.login_isActive ? "Active" : "Inactive",
  }));


  const tableColumns = [
    { id: 1, name: "COMPANY" },
    { id: 2, name: "EMAIL" },
    { id: 3, name: "STATUS" },
    { id: 4, name: "BLOCK USER"},

  ];
  const handleButtonClick = () => {
    console.log("Button clicked!");
  };

  const blueIconStyles = {
    color: PrimaryColor,
  };

  const handleSearchChange = (searchValue) => {
    setSearchInput(searchValue);
  };
  return (
    <>
      <Header
        onClick={handleButtonClick}
        heading="Users List"
        headingIcon={<PeopleRoundedIcon style={blueIconStyles} />}
      />
      <ListFilterWrapper>
        <SearchFilter
          leftText={`Total Users (${data?.getUser.totalCount})`}
          onSearchChange={handleSearchChange}
        />

        <UserTable
          TableData={paginatedCompanies}
          columns={tableColumns}
          refetch={refetch}
          authHeader={authHeader}
        />

        <Box
          p={2}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <ExportButton data={exportAllData} fileName="AllUsersData.xlsx" />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            rowsPerPage={rowsPerPage}
            page={page}
            count={users.length}
            component="div"
            onPageChange={handlechangepage}
            onRowsPerPageChange={handleRowsPerPage}
          />
        </Box>
      </ListFilterWrapper>
    </>
  );
};

export default Users;

import AddIcon from "@mui/icons-material/Add";
import styled from "@emotion/styled";
import AcceleratorTable from "src/components/Table/AcceleratorTable";
import { useEffect, useState } from "react";
import { AcceleratorIcon } from "../exports/SVG";
import SearchFilter from "../SearchFilter";
import { DuneColor, WhiteColor } from "src/theme/Theme";
import Header from "../Header";
import NewCourse from "../Modal/NewCourse";
import { useLazyQuery } from "@apollo/client";
import { CoursesList } from "src/hook/query/getCourses";
import NoResultFound from "../NoResultFound";
import SuspenseLoader from "../SuspenseLoader";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";
import ExportButton from "../Button/ExportButton";
import { Box, TablePagination } from "@mui/material";

const ListFilterWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  background: ${WhiteColor};
  padding: 20px;
  margin: 20px 0;
`;

const Goback = styled(Link)`
display: flex;
align-items:center;
font-weight: 600;
color:${DuneColor};
cursor: pointer;
text-decoration: none;
margin-top:4px;
`
const CoursesListUi = ({ courseIdState }) => {
  const authHeader = localStorage.getItem("TOKEN");
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [courseIdSingle, setCourseIdSingle] = useState("")
  useEffect(() => {
    courseslist({
      variables: {
        "coursesId": courseIdState
      },

    })

  }, [courseIdState, authHeader]);

  const [courseslist, { loading, error, data, refetch }] = useLazyQuery(CoursesList, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${authHeader}`,
      },
    },
  }
  );


  if (loading) return <SuspenseLoader />;
  if (error) return <p>Error: {error.message}</p>;

  const handleButtonClick = () => {
    setOpen(true);
    setCourseIdSingle("")
  };

  const handleSearchChange = (searchValue) => {
    // Handle search value change
    setSearchInput(searchValue);
  };

  let dataArr = data?.courses?.edges


  if (searchInput) {
    dataArr = dataArr?.filter((company) => {
      const companyName = company.node.title?.toLowerCase();
      return companyName?.includes(searchInput?.toLowerCase());
    });
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const paginatedCompanies =
    dataArr &&
    dataArr?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

  const exportAllData = dataArr?.map((item) => ({
    Title: item.node.title,
    Description: item.node.description,
    Type: item.node.type,
    Date: item.node.accelerator.createdAt,
    DOC_PDF: item.node.link || "NA",
    VideoTitle: item.node.videoTitle || "NA",
    VideoURL: item.node.videoUrl || "NA"
  }));

  const checkLength = dataArr?.length


  const GetCourseIdCallback = (val) => {
    if (val) {
      
      setCourseIdSingle(val)
      setOpen(true)
    }
  }
  return (
    <>
      {open && 
      <NewCourse 
      open={open} 
      setOpen={setOpen} 
      refetch={refetch} 
      courseIdState={courseIdState} 
      courseIdSingle={courseIdSingle}
      />}

      <Header
        onClick={handleButtonClick}
        heading="Courses List"
        headingIcon={AcceleratorIcon}
        buttonIcon={<AddIcon />}
        buttonText="Add New Course"
      />
      <Goback to="/auth/accelerator"><ArrowBackIcon />&nbsp;Go Back</Goback>
      <ListFilterWrapper>
        <SearchFilter
          leftText={`Total Courses (${dataArr?.length})`}
          onSearchChange={handleSearchChange}
        />
        {!checkLength &&
          <NoResultFound />
        }
        {checkLength > 0 && <>

          <AcceleratorTable
            courseData={paginatedCompanies}
            refetch={refetch}
            authHeader={authHeader}
            GetCourseIdCallback={GetCourseIdCallback}
          />
          <Box
            p={2}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <ExportButton
              data={exportAllData}
              fileName="AcceleratorListData.xlsx"
            />

            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 25]}
              count={dataArr?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>

        </>
        }
      </ListFilterWrapper>
    </>
  );
};

export default CoursesListUi;

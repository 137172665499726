import { gql } from "@apollo/client";
export const AcceleratorList = gql`
  query Query($search: String) {
  accelerators(search: $search) {
    totalCount
    edges {
      node {
        id
        name
        description
        createdBy {
          id
          email
        }
        img
        status
        createdAt
        avgRating
        highlight
      }
      cursor
    }
  }
}
`
import styled from '@emotion/styled';
import { Typography, Paper } from '@mui/material';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
`;

const StyledPaper = styled(Paper)`
  padding: 20px;
  text-align: center;
`;

const NoResultImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const NoResultFound = () => {
  return (
<Container>
      <StyledPaper elevation={3}>
      <Typography variant="h3" style={{ fontFamily: 'Montserrat' }}>
          No data found
        </Typography>
        <NoResultImage src="/nodata.jpg" alt="No Result Found" width={250} height={250}/>
       
       
      </StyledPaper>
    </Container>  )
}

export default NoResultFound
import { useMutation } from "@apollo/client";
import { UserBlock } from "src/hook/mutations/blockUser";
import { errorToast, successToast } from "src/utils/toaster";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Button as MUIButton, Switch } from "@mui/material";
import styled from "@emotion/styled";
import {
  AliceBlue,
  DuneColor,
  ErrorColor,
  PrimaryColor,
  SuccessColor,
  WhiteColor,
} from "../../theme/Theme";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: WhiteColor,
    color: PrimaryColor,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: AliceBlue,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));



const Cellstyles = {
  cell: {
    color: PrimaryColor,
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
  },
};

const Contentstyles = {
  cell: {
    color: DuneColor,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
  },
};

const UserTable = ({ TableData, columns, refetch, authHeader }) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [blockUserMutation] = useMutation(UserBlock, { onCompleted: refetch });

  const blockUserHandler = (companyId, companyStatus) => {
    blockUserMutation({
      variables: {
        input: {
          id: companyId,
          status: !companyStatus,
        },
      },
      context: {
        headers: {
          Authorization: `Bearer ${authHeader}`,
        },
      },
    })
      .then((result) => {
        if (result) {
          successToast("User blocked successfully");
        }
      })
      .catch((error) => {
        errorToast(error);
      });
  };
  const handleButtonClick = () => {
    console.log("Button clicked!");
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((item, index) => {
              return (
                <StyledTableCell
                  align="center"
                  style={Cellstyles.cell}
                  key={item.id}
                >
                  {item.name}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {TableData?.map((user) => (
            <StyledTableRow key={user.node.id}>
              <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                {user.node.fullName}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                {user.node.email}
              </StyledTableCell>

              <StyledTableCell align="center" style={Contentstyles.cell}>
                <MUIButton
                  onClick={handleButtonClick}
                  variant="contained"
                  style={{
                    borderRadius: "5px",
                    backgroundColor: user.node.login_isActive
                      ? SuccessColor
                      : ErrorColor,
                    color: WhiteColor,
                    padding: "4px 30px",

                    fontFamily: "Montserrat",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  {user.node.login_isActive ? "Active" : "Inactive"}
                </MUIButton>
              </StyledTableCell>

              <StyledTableCell
                component="th"
                scope="row"
                style={Contentstyles.cell}
                align="center"
              >
                <IconButton
                //   sx={{
                //     "&:hover": {
                //       background: theme.colors.error.lighter,
                //     },
                //     color: theme.palette.error.main,
                //   }}
                  color="inherit"
                  size="small"
                  onClick={() =>
                    blockUserHandler(user.node.id, user.node.login_isActive)
                  }
                >
                  <Switch
                    {...label}
                    defaultChecked={user.node.login_isActive}
                  />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;

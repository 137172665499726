import React, { useState } from "react";
import { useNavigate } from "react-router";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { DuneColor, WhiteColor, AliceBlue } from "src/theme/Theme";
import { DownArrow } from "./exports/SVG";
import logo from "../Asset/WaLeadLogo.png";
import AvatarWithName from "src/avatar/AvatarWithName";

// Styled components
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoutButton = styled(Button)`
  cursor: pointer;
  padding-right: 30px;
  display: flex;
  align-items: center;
`;

const DownArrowContainer = styled.div`
  padding-left: 10px;
`;

const TextStyle = {
  color: DuneColor,
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: 600,
};

// Main Header component
const SidebarHeader = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("UserInfo");
    handleClose();
    navigate("/login");
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;
  const userInfoString = localStorage.getItem("UserInfo");

  if (!userInfoString) {
    // Redirect to the login page if UserInfo is not found
    navigate("/login");
    return null; 
  }

  const userName = JSON.parse(userInfoString);
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: WhiteColor,
      }}
    >
      <FlexContainer>
        <Toolbar >
          {/* Logo */}
          <img src={logo} alt="logo" style={{width:"150px",height:"auto"}}/>
        </Toolbar>

        {/* User Avatar and Logout Button */}
        <LogoutButton onClick={handleClick} aria-describedby={popoverId}>
          <AvatarWithName
            width="30"
            height="30"
            imageUrl=""
            text={userName.email}
            textSx={TextStyle}
          />
          <DownArrowContainer>{DownArrow}</DownArrowContainer>
        </LogoutButton>

        {/* Popover */}
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            boxShadow: `0px 4px 8px ${AliceBlue}`,
          }}
        >
          {/* Logout Button in Popover */}
          <Typography
            sx={{
              p: 2,
              width: "150px",
              textAlign: "center",
              cursor: "pointer",
              fontSize: "12px",
              borderRadius: "4px",
              backgroundColor: WhiteColor,
              color: DuneColor,
              fontWeight: "bold",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: AliceBlue,
              },
            }}
            onClick={handleLogout}
          >
            Logout
          </Typography>
        </Popover>
      </FlexContainer>
    </AppBar>
  );
};

export default SidebarHeader;

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Outlet, Navigate } from "react-router-dom";
import Sidebar from "src/components/Sidebar";
import SidebarHeader from "src/components/SidebarHeader";
import { HarpColor } from "src/theme/Theme";

const ProtectedRoute = () => {
  const authKey = localStorage.getItem("TOKEN");

  return authKey ? (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <SidebarHeader />
        <Sidebar />

        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, background: HarpColor, height: "100vh" }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;

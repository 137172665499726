import AddIcon from "@mui/icons-material/Add";
import styled from "@emotion/styled";
import { WhiteColor } from "src/theme/Theme";
import Header from "../Header";
import { AcceleratorIcon } from "../exports/SVG";
import SearchFilter from "../SearchFilter";
import { useState } from "react";
import NewAccelerator from "../Modal/NewAccelerator";
import SuspenseLoader from "../SuspenseLoader";
import { useQuery } from "@apollo/client";
import { AcceleratorList } from "src/hook/query/getAccelerator";
import AcceleratorListTable from "../Table/AcceleratorListTable";
import { Box, TablePagination } from "@mui/material";
import ExportButton from "../Button/ExportButton";
import NoResultFound from "../NoResultFound";

const ListFilterWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  background: ${WhiteColor};
  padding: 20px;
  margin: 20px 0;
`;

const AcceleratorUi = () => {
  const authHeader = localStorage.getItem("TOKEN");
  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [accId, setAccId] = useState("")
  const { loading, error, data, refetch } = useQuery(AcceleratorList, {
    variables: {
      search: searchInput,
    },
    context: {
      headers: {
        Authorization: `Bearer ${authHeader}`,
      },
    },
  });


  if (error) return <p>Error: {error.message}</p>;

  const handleButtonClick = () => {
    setOpen(true);
    setAccId("")
  };



  const handleSearchChange = (searchValue) => {
    // Handle search value change
    setSearchInput(searchValue);
  };



  let AcceleratorData = data?.accelerators?.edges;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const paginatedCompanies =
    AcceleratorData &&
    AcceleratorData?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    const exportAllData = AcceleratorData?.map((item) => ({
      Name: item.node.name,
      Description: item.node.description,
      Image: item.node.img,
      Date: item.node.createdAt,
      Status: item.node.status ? "Active" : "Inactive",
    }));

  const GetAccIdCallback = (val: string) => {
    if (val) {
      setOpen(true)
      setAccId(val)
    }
  }

  const checkLength = AcceleratorData?.length
  const tableColumns = [
    { id: 1, name: "NAME" },
    { id: 2, name: "DESCRIPTION" },
    { id: 3, name: "IMAGE" },
    { id: 5, name: "DATE" },
    { id: 4, name: "STATUS" },
    { id: 6, name: "BLOCK" },
    { id: 7, name: "EDIT" },
    { id: 8, name: "DELETE" },
    { id: 9, name: "GO TO COURSES" },

  ];
  return (
    <>
      {open && (
        <NewAccelerator open={open} setOpen={setOpen} refetch={refetch} accId={accId} setAccId={setAccId} />
      )}
      <Header
        onClick={handleButtonClick}
        heading="Accelerator List"
        headingIcon={AcceleratorIcon}
        buttonIcon={<AddIcon />}
        buttonText="Add New Accelerator"
      />
      <ListFilterWrapper>
        <SearchFilter
          leftText={`Total Accelerator (${AcceleratorData?.length})`}
          onSearchChange={handleSearchChange}
        />
        {loading && <SuspenseLoader />}
        {!checkLength && <NoResultFound />}

        {checkLength>0 && (
          <>
            <AcceleratorListTable
              TableData={paginatedCompanies}
              columns={tableColumns}
              refetch={refetch}
              authHeader={authHeader}
              GetAccIdCallback={GetAccIdCallback}
            />
            <Box
              p={2}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <ExportButton
                data={exportAllData}
                fileName="AcceleratorListData.xlsx"
              />

              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                count={AcceleratorData?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </>
        )}
      </ListFilterWrapper>
    </>
  );
};

export default AcceleratorUi;

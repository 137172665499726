import { Avatar, Typography } from "@mui/material";
import styled from "@emotion/styled";

const AvatarWithName = ({ width, height, imageUrl, text,textSx }) => {
  const Flex = styled.div`
    display: flex;
    align-items: center;
  `;
  return (
    <Flex>
      <Avatar
        style={{
          width: `${width}px`,
          height: `${height}px`,
          marginRight: "8px",
        }}
        alt={text}
        src={imageUrl}
      />
      {text && (
        <Typography
          variant="body1"
          sx={textSx}
        >
          {text}
        </Typography>
      )}
    </Flex>
  );
};

export default AvatarWithName;

import { gql } from '@apollo/client';

export const CompanyBlock = gql`
mutation BlockCompany($input: CompanyBlockInput) {
    blockCompany(input: $input)
  }
`;




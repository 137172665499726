
import { gql } from '@apollo/client';

export const UserBlock = gql`
mutation BlockUser($input: UserBlockInput) {
    blockUser(input: $input) {
      id
      email
      mobileNumber
      countryCode
      jobRole
      experience
      firstWishlist
      company {
        id
        name
        isActive
      }
      weLeadUserInCompany
      fullName
      companySize
      profilePicture
      industry
    }
  }
`;




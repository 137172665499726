import PropTypes from "prop-types";
import { FormControl, MenuItem, Select } from "@mui/material";
import styled from "@emotion/styled";
import { DuneColor, ErrorColor } from "src/theme/Theme";

const DropDownContainer = styled.div`
  width: 454px;
  margin-bottom:30px;

`;

const Label = styled.label`
  color: ${DuneColor};
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
`;

const ErrorMessage = styled.div`
  color: ${ErrorColor};
  font-style: italic;
  font-size: 12px;
  font-weight: 600;
`;

const DropDown = ({ options, label, error, onChange, value }) => {
  return (
    <DropDownContainer>
      {label && <Label>{label}</Label>}
      <FormControl fullWidth>
        <Select
          value={value}
          onChange={onChange}
          displayEmpty
          sx={{ height: "44px" }}
        >
          <MenuItem value="" disabled>
            Select one
          </MenuItem>
          {options?.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </DropDownContainer>
  );
};

DropDown.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

export default DropDown;


import { gql } from '@apollo/client';

export const CourseAdd = gql`
mutation Mutation($input: CourseInput) {
  createCourse(input: $input) {
    id
    title
    description
    type
    link
    videoUrl
    videoTitle
    accelerator {
      id
      name
      description
      createdBy {
        id
        email
      }
      img
      status
      createdAt
      avgRating
      highlight
    }
    status
  }
}
`;


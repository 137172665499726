import Header from "../components/Header";
import { DashBoardIcon } from "../components/exports/SVG";

const Dashboard = () => {

  return (
    <>
      <Header heading="Dashboard" headingIcon={DashBoardIcon} />
    </>
  );
};

export default Dashboard;

import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import styled from "@emotion/styled";
import { ErrorColor } from "src/theme/Theme";

const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const ErrorMessage = styled.div`
  color: ${ErrorColor};
  font-style: italic;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
`;
const AvatarFileUpload = ({
  altText,
  badgeContent,
  avatarSize,
  selectedFile,
  setSelectedFile,
  error,
  setInputErrors
}) => {
  const handleFileChange = (event) => {
    setInputErrors({
      name: "",
      description: "",
      setSelectedFile: "",
    })
    const file = event.target.files[0];
    setSelectedFile(file);

  };

  const isImageChecker = (isImage) => {
    if (!isImage) {
      return 
    }
    return typeof (selectedFile) !== "string" ? URL.createObjectURL(selectedFile) : selectedFile
  }
  return (
    <>
      <BadgeContainer>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={badgeContent}
          sx={{ margin: "20px 0" }}
        >
          <label htmlFor="avatar-input">
            <Avatar
              alt={altText}
              src={isImageChecker(selectedFile)}
              style={{
                width: avatarSize || "107px",
                height: avatarSize || "107px",
                borderRadius: avatarSize || "107px",
                cursor: "pointer",
              }}
            />
          </label>
          <input
            id="avatar-input"
            type="file"
            accept=" image/jpg, image/jpeg, image/png"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </Badge>
      </BadgeContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default AvatarFileUpload;

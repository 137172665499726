import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { ReactNode } from "react"; 

import { PrimaryColor, WhiteColor } from "../theme/Theme";

interface HeaderProps {
  headingIcon?: ReactNode;
  buttonIcon?: ReactNode;
  onClick?: () => void;
  heading?: string;
  buttonText?: string;
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextIconContainer = styled.div`
display: flex;
align-items: center;
`;

const ButtonIconContainer = styled.div``;

const Heading = styled.span`
  color: ${PrimaryColor};
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  margin-left: 10px;
`;

const Header: React.FC<HeaderProps> = ({
  headingIcon,
  buttonIcon,
  onClick,
  heading,
  buttonText,
}) => {
  return (
    <HeaderContainer>
      <TextIconContainer>
        {headingIcon}
        <Heading>{heading}</Heading>
      </TextIconContainer>
      <ButtonIconContainer>
      {buttonText && <Button
          variant="contained"
          sx={{
            background: PrimaryColor,
            color: WhiteColor,
            borderRadius: "0",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: "600",
          }}
          startIcon={buttonIcon}
          onClick={onClick}
        >
          {buttonText}
        </Button>}  
      </ButtonIconContainer>
    </HeaderContainer>
  );
};

export default Header;

import { Button } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import * as XLSX from "xlsx";

function ExportButton({ data ,fileName}) {
  const exportToExcel = () => {
    const exportData = data

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "UsersData");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  return (
    <Button
      variant="outlined"
      startIcon={<ImportExportIcon />}
      onClick={exportToExcel}
    >
      Export All
    </Button>
  );
}

export default ExportButton;
